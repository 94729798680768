<template>
  <router-view />
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="less">
  body {
    margin: 0;
    padding: 0;
  }
  #app {
    --primary-color: #376f6c;
  }
</style>
