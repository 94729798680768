import {
  createStore
  // createLogger, Plugin
} from 'vuex'

const store = createStore({
  // modules: {},
  // plugins,
})

export default store
